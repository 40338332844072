<div
  [class]="'confirmation-modal--' + dialogData.style"
  [style.width]="dialogData.width"
  class="confirmation-modal"
>
  <div class="confirmation-modal__header">
    <div class="confirmation-modal__header-title">{{ dialogData.title | translate }}</div>

    <div class="confirmation-modal__header-description">
      {{ dialogData.description | translate }}
    </div>

    @if (dialogData.rememberDecisionId) {
      <app-checkbox
        class="confirmation-modal__header-remember-decision"
        [label]="'common.buttons.dontAskAgain' | translate"
        [checked]="rememberDecision()"
        (change)="rememberDecision.set($event.target['checked'])"
        class="confirmation-modal__header-remember-decision"
      >
      </app-checkbox>
    }
  </div>

  <div class="confirmation-modal__actions">
    @if (dialogData.confirmActionText) {
      <app-button
        (click)="handleDecision('confirm')"
        [size]="ButtonSize.Small"
        [type]="dialogData.destructive ? ButtonType.Danger : ButtonType.Primary"
      >
        {{ dialogData.confirmActionText | translate }}
      </app-button>
    }

    @if (dialogData.secondaryActionText) {
      <app-button
        (click)="handleDecision('secondaryAction')"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Secondary"
        class="confirmation-modal__actions-secondary-action"
      >
        {{ dialogData.secondaryActionText | translate }}
      </app-button>
    }

    @if (dialogData.cancelActionText) {
      <app-button
        (click)="handleDecision('cancel')"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Secondary"
        class="confirmation-modal__actions-cancel-action"
      >
        {{ dialogData.cancelActionText | translate }}
      </app-button>
    }
  </div>
</div>
